import {
  Button,
  Container,
  Drawer,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FathomClient from "api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ProgramSetup from "./steps/setup";
import DynamicInput from "@einhorn/dynamicInput";
import Title from "@einhorn/title";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "@einhorn/confirmDialog";

export default function CreateProgram({ drawerOpen, toggleDrawer, editProgramId, width }: any) {

  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const [program, setProgram] = useState({
    name: "",
    description: "",
    outcomes: ["", "", "", ""],
    id: null,
    startDateUtc: dayjs()
  });

  useEffect(() => {

    let programId = id || editProgramId;
    if (programId !== "" && programId !== undefined) {
      setLoading(true);
      const fetchData = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`{clientId}/programs/` + programId);
        if (data.outcomes == null) {
          data.outcomes = [];
        }

        setProgram(data);
        setLoading(false);
      };

      fetchData().catch(console.error);
    }

  }, [id, editProgramId, getAccessTokenSilently]);


  const saveProgram = async () => {
    let programId = id || editProgramId;
    if (programId !== "" && programId !== undefined) {
      const apiClient = new FathomClient(await getAccessTokenSilently());

      setProgram({ ...program });
      const { data } = await apiClient.put(
        `{clientId}/programs/` + program.id,
        program
      );
      navigate("/programs/" + data.id)

    } else {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      setProgram({ ...program });
      program.id = null;
      const { data } = await apiClient.post(
        `{clientId}/programs/`,
        program
      );
      navigate("/programs/" + data.id)
    }
  }

  const deleteProgram = async () => {
    const apiClient = new FathomClient(await getAccessTokenSilently());
    if (program.id !== "") {
      await apiClient.delete(
        `{clientId}/programs/` + program.id
      );

      navigate("/programs/")
    }
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    marginTop: "25px"
  }));

  return (
    <Drawer
      anchor='right'
      sx={{
        width: "600px",
      }}
      open={drawerOpen}
      PaperProps={{
        sx: { width: "800px" },
      }}
    >
      <ConfirmDialog
        title="Delete program"
        content="Are you sure you want to delete this program?"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        confirmAction={deleteProgram}
      />
      <DrawerHeader >
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={6}><Stack spacing={2} direction="row" justifyContent="left" sx={{ mt: '10px', ml: "15px" }}>
              <Button hidden={program.id == ""} variant="outlined" color="error" onClick={() => setConfirmDeleteOpen(true)} >
                Delete
              </Button>
            </Stack></Grid>
            <Grid item xs={6}> <Stack spacing={2} direction="row" justifyContent="right" sx={{ mr: '10px', mt: "5px" }}>
              <IconButton aria-label="open" onClick={() => toggleDrawer(false)}>
                <CloseOutlinedIcon  />
              </IconButton>
            </Stack></Grid>
          </Grid>


        </Container>
      </DrawerHeader>
      <br />

      <Container>
        <Container>
          <ProgramSetup value={program} setValue={setProgram} />
          <br />
          <Title>Learning outcomes</Title>
          <Typography variant="subtitle1" gutterBottom>
            Set up the learning outcomes for your program, so we can understand your goals and generate the most useful report for you.
          </Typography>
          <DynamicInput value={program} setValue={setProgram} property="outcomes" label="Outcome" />
          <Stack spacing={2} direction="row" justifyContent="right" sx={{ mt: '0px' }}>
            <Button variant="outlined" onClick={saveProgram} sx={{ mt: 1, mr: 1 }} >
              Save
            </Button>
          </Stack>
        </Container>
      </Container>
    </ Drawer>
  )
}
