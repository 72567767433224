import Highcharts from "highcharts";
import Variablepie from "highcharts/modules/variable-pie";
import HighchartsReact from "highcharts-react-official";
import { Alert } from "@mui/material";

Variablepie(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
export default function EngagementBarChart({ chart }: any) {

    let seriesData = [] as any[];
    let firstData = [] as any[];
    let needsWarning = false;
    Object.keys(chart.series as any).forEach( (key:string) => 
        {
            if(chart.series[key].length > 1) {
                needsWarning = true;
            }

            if(chart.series[key][0] > 0){
                firstData.push({
                    name: key,
                    y: chart.series[key][0]
                })
            }
        });

    seriesData.push({
        name: "Total",
        type: "pie",
        size: '50%',
        innerSize: '0%',
        data: firstData
    })

    let options: Highcharts.Options = getPieChartOptions(seriesData);

    return (
        <>
            {needsWarning && <Alert severity="info">Only one question can be displayed on a bar chart (we&apos;re displaying the first one)</Alert> }
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                sx={{mb:"-50px"}}
            />
        </>
    );
}

function getPieChartOptions(seriesData:any ): Highcharts.Options {
    return {
        chart: {
            type: "variablepie",
            backgroundColor: 'rgba(0,0,0,0.0)',
            plotBorderWidth: 0,
            margin: [-50, 0, 0, 10],
        },
        tooltip: {
            valueSuffix: ''
        },
        title: {
            text: "",
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                borderWidth: 2,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    distance: 20,
                    style: {
                        fontSize: '12px', 
                        fontWeight: 'bold', 
                        color: '#333333', 
                        textOutline: '1px contrast', 
                        fontFamily: 'Arial, sans-serif' 
                    },
                    formatter: function() {
                        return `${this.point.name}: ${this.y} (${Math.round(this.percentage)}%)`;
                    }
                },
            }
        },
        series: seriesData
    };
}