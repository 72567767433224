import { useAuth0 } from "@auth0/auth0-react";
import Title from "@einhorn/title";
import { Container, Toolbar, Grid, Button, Paper } from "@mui/material";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function AcceptInvite() {
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [invite, setInvite] = useState({
        clientName: ""
    });
    const [loading, setLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [searchParams] = useSearchParams();
    const fetchData = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.get(`clients/invite?code=` + searchParams.get("code"));
        setInvite(data);
        setLoading(false)
    };

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            fetchData()
                .catch((e) => {
                    console.error(e);
                    setIsClicked(false)
                }
                );
        }
    }, [getAccessTokenSilently]);

    const acceptInvite = async () => {
        if(!isClicked) {setIsClicked(true)}
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.post(`clients/invite/accept?code=` + searchParams.get("code"), null);
        localStorage.removeItem("saved_invite");
        cookies.set('x-fathom-clientId', data, {
            path: "/"
          } );
        await getAccessTokenSilently({ cacheMode: 'off' });
        window.location.assign("/programs")
      };


    return (
        <Container>
            <Container>
                <Toolbar />
                <Grid item xs={12}>

                    <Paper>
                    <br /><br />
                        <Container>
                            <Title> You have been invited to use Fathom</Title>
                        <hr />
                        <br /><br />
                        <p>You have been invited to join team <b>{invite.clientName}</b></p>
                        <br /><br />
                        <Button variant="outlined" disabled = {isClicked}  onClick={acceptInvite}>Accept Invite</Button>
                        <br /><br />
                        </Container>
                        </Paper>
                </Grid>
            </Container>
        </Container >
    );
}