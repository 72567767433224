import Highcharts from "highcharts";
import Variablepie from "highcharts/modules/variable-pie";
import HighchartsReact from "highcharts-react-official";

Variablepie(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
export default function EngagementBarChart({ chart }: any) {
    let seriesData = [] as any[];
    Object.keys(chart.series as any).forEach( (key:string) => 
    {
        seriesData.push({
            name: key,
            type: "column",
            data: chart.series[key].map((z:any)=> {
                return {
                    name: key,
                    y: z
                }
            })
        })
    });

    
    let options: Highcharts.Options = getBarChartOptions(chart, seriesData);


    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
}

function getBarChartOptions(chart : any, seriesData: any): Highcharts.Options {
    return {
        chart: {
            type: "column",
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            margin: [100, 6, 60, 60],
        },
        tooltip: {
            valueSuffix: ''
        },
        title: {
            text: "",
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                },
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Count'
            }
        },
        xAxis: {
            categories: chart.categories,
            crosshair: true,
            accessibility: {
                description: 'Totals'
            }
        },
        series: seriesData
    };
}