import Title from "@einhorn/title";
import { Container, Drawer, IconButton, Stack, styled } from "@mui/material";
import ReportList from "../reports/reportList";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EngagementList from "../engagement/engagementList";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    marginTop: "78px"
}));


export default function DataDrawer({ currentTab, drawerOpen, width, programId, toggleDrawer, currentEngagementReportId, currentReportId, setFirstReport }: any) {

    return (
        <Drawer
            sx={{
                width: width,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: width,
                },
            }}
            variant="persistent"
            anchor="right"
            open={drawerOpen}
        >
            <DrawerHeader >
                <Container>
                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ mt: '-35px' }}>
                        <IconButton aria-label="open" onClick={() => toggleDrawer()} >
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Stack>
                    <Title>{currentTab === "reports" || currentTab === "insights" ? "Reports" : "Engagement"} </Title>
                </Container>
            </DrawerHeader>
            {(currentTab === "reports" || currentTab === "insights")  &&
                <ReportList programId={programId} currentReportId={currentReportId} setFirstReport={setFirstReport} />
            }
            {currentTab === "engagement" &&
                <EngagementList programId={programId} currentEngagementReportId={currentEngagementReportId} setFirstReport={setFirstReport} />
            }
        </Drawer>
    )
}