
export interface EngagementDataPoint {
    title: string;
    order: number;
    type: EngagementChartType;
    filter: EngagementDataFilter;
    id: string | undefined;
}

export enum EngagementChartType {
    Bar = 0,
    Pie = 1,
    Series = 2
}

export interface EngagementDataFilter {
    questionHeaderIdFilter: string[];
    responseFilter: string[];
}

export interface EngagementDataQueryResponse {
    categories: string[];
    series: Map<string, number[]>
}

export interface EngagementQuestionOptionsResponse {
    headerResponses: EngagementQuestionHeaderOption[];
}

export interface EngagementQuestionHeaderOption {
    questionHeaderId: string;
    questionHeader: string;
    responses: string[];
}

export interface EngagementQuestionHeaderOptionNoResponse {
    questionHeaderId: string;
    questionHeader: string;
}