import { useAuth0 } from "@auth0/auth0-react";
import Title from "@einhorn/title";
import { Container, Toolbar, Grid, Paper, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
export const CallbackPage = () => {
  const env = process.env.REACT_APP_ENV;
  const cookies = new Cookies();
  const { user, error, loginWithRedirect  } = useAuth0();

  const navigate = useNavigate();
  
  if (error) {

    return (
      <div>
        <Container>
          <Toolbar />
          <Grid item xs={12}>

            <Paper>
              <br /><br />
              <Container>
                <Title>Issue logging in</Title>
                <hr />
                <br /><br />
                <p>{error.message}</p>

                <br /><br />
                <Button variant="outlined" onClick={() => loginWithRedirect()}>Login</Button>
                <br /><br />
              </Container>
            </Paper>
          </Grid>
        </Container>
      </div>
    );
  }

  const urlParams = new URLSearchParams(window.location.search);

  window.location.href = "/login?" + urlParams;

  return (
    <div className="page-layout">
    </div>
  );
};