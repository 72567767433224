

interface CopyChartOptions {
    containerSelector?: string;
    svgSelector?: string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
}


export default function createChartCopyHandler(options: CopyChartOptions = {}) {

    const defaultOptions: CopyChartOptions = {
        containerSelector: '.engagement-box',
        svgSelector: '.highcharts-root',
        onSuccess: () => console.log('Chart copied to clipboard!'),
        onError: (error) => console.error('Error copying to clipboard:', error)
        };

    const opts = { ...defaultOptions, ...options };
        
    return async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const button = event.currentTarget;
      const container = button.closest(opts.containerSelector!) as HTMLElement;
        console.log(button);
        // console.log( button.closest);

      if (!container) {
        throw new Error(`Container not found with selector: ${opts.containerSelector}`);
      }
  
      const svg = container.querySelector(opts.svgSelector!) as SVGElement;
      if (!svg) {
        throw new Error(`SVG not found with selector: ${opts.svgSelector}`);
      }
  
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          throw new Error('Failed to get canvas context');
        }
        
        const svgData = new XMLSerializer().serializeToString(svg);
        const img = new Image();
  
        await new Promise<void>((resolve, reject) => {
          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
  
            canvas.toBlob(async (blob) => {
              if (!blob) {
                reject(new Error('Failed to create blob from canvas'));
                return;
              }
              try {
                await navigator.clipboard.write([
                  new ClipboardItem({
                    'image/png': blob
                  })
                ]);
                opts.onSuccess?.();
                resolve();
              } catch (err) {
                const error = err instanceof Error ? err : new Error('Unknown error occurred');
                opts.onError?.(error);
                reject(error);
              }
            }, 'image/png');
          };
  
          img.onerror = () => {
            reject(new Error('Failed to load SVG as image'));
          };
  
          img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
        });
      } catch (error) {
        const err = error instanceof Error ? error : new Error('Unknown error occurred');
        opts.onError?.(err);
        throw err;
      }
    };
}