import { useAuth0 } from "@auth0/auth0-react";
import { Modal, Box, DialogTitle, Typography, IconButton, Grid, TextField, CircularProgress, Alert, List, Tooltip, Checkbox, Button, Chip, FormControl, Input, InputLabel, MenuItem, Select, FormLabel, RadioGroup, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { EngagementChartType, EngagementDataPoint, EngagementDataQueryResponse, EngagementQuestionHeaderOption, EngagementQuestionHeaderOptionNoResponse, EngagementQuestionOptionsResponse } from "../../engagementTypes";
import FathomClient from "@api/fathomapi";
import EngagementBarChart from "../charts/engagementBarChart";
import PreviewBarChart from "../charts/engagementPieChart";
import Radio from '@mui/material/Radio';
import EngagementSeriesChart from "../charts/engagementSeriesChart";
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import ContentCopy from '@mui/icons-material/ContentCopy';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import createChartCopyHandler from '../addChart/copyUtils';
import { enqueueSnackbar } from "notistack";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function EditChart({ programId, engagementId, open, setModalOpen, order, fetchData, selectedChart, reportStructure }: any) {
    const [chartDataPoint, setChartDataPoint] = useState({
        title: "",
        order: order,
        type: EngagementChartType.Bar,
        filter: {
            questionHeaderIdFilter: [],
            responseFilter: []
        },
        id: ""
    } as EngagementDataPoint);
    const [optionsLoading, setOptionsLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [questionFilter, setQuestionFilter] = useState([] as EngagementQuestionHeaderOptionNoResponse[]);
    const [loadedType, setLoadedType] = useState(Number);

    const [chartQueryData, setChartQueryData] = useState({
        categories: [],
        series: new Map<string, number[]>
    } as EngagementDataQueryResponse);

    const [chartOptions, setChartOptions] = useState({
        headerResponses: []
    } as EngagementQuestionOptionsResponse);

    const { getAccessTokenSilently } = useAuth0();
    const canSave = () => {
        return chartDataPoint.title !== "" && chartDataPoint.filter.questionHeaderIdFilter.length > 0;
    }

    const getData = async () => {
        setDataLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());

        var filter = {
            questionHeaderIdFilter: chartDataPoint.filter.questionHeaderIdFilter.map((y: any) => { return (y.questionHeaderId || y) }),
            responseFilter: chartDataPoint.filter.responseFilter
        }

        const response = await apiClient.post(`{clientId}/programs/${programId}/engagement/${engagementId}/chart/data`, filter);
        setChartQueryData(response.data);
        setDataLoading(false);
    }

    const getOptions = async () => {
        setOptionsLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const response = await apiClient.get(`{clientId}/programs/${programId}/engagement/${engagementId}/chart/options`);
        let options = response.data
        setChartOptions(options);

        let datapoint = reportStructure.engagementDataPoints.filter((y: any) => y.dataPointId == selectedChart.dataPointId)[0];

        let headerMap = new Map<string, string>();
        options.headerResponses.map((y:any) => {
            headerMap.set(y.questionHeaderId, y.questionHeader);
            return y.questionHeaderId;
        })

        let filter = {
            questionHeaderIdFilter: datapoint.filter.questionHeaderIdFilter.map((y: any) => { 
                const fullHeader = options.headerResponses.find((h: EngagementQuestionHeaderOption) => h.questionHeaderId === y);
                return fullHeader; 
            }),
            responseFilter: datapoint.filter.responseFilter
        }

        setLoadedType(EngagementChartType[datapoint.type as keyof typeof EngagementChartType]);
        setQuestionFilter(filter.questionHeaderIdFilter)
        setChartDataPoint({ ...datapoint, filter: filter, type: EngagementChartType[datapoint.type as keyof typeof EngagementChartType] });
        setOptionsLoading(false);
    }

    useEffect(() => {
        getOptions();

    }, [order, open])


    useEffect(() => {
        if (chartDataPoint.filter.questionHeaderIdFilter.length > 0 && chartDataPoint.filter.questionHeaderIdFilter[0] != null) {
            getData();
        }
    }, [chartDataPoint.filter])

    const clearModal = () => {
        setModalOpen(false);
        setChartOptions({
            headerResponses: []
        })
        setChartQueryData({
            categories: [],
            series: new Map<string, number[]>
        })

        setChartDataPoint({
            title: "",
            order: order,
            type: EngagementChartType.Bar,
            filter: {
                questionHeaderIdFilter: [],
                responseFilter: []
            },
            id: ""
        })

    };

    const handleHeaderChange = (event: any) => {

        setQuestionFilter(event.target.value)
        setChartDataPoint({
            ...chartDataPoint, filter: {
                ...chartDataPoint.filter,
                questionHeaderIdFilter: event.target.value
            }
        })

    };

    const handleResponseChange = (event: any) => {
        
        setChartDataPoint({
            ...chartDataPoint, filter: {
                ...chartDataPoint.filter,
                responseFilter: event.target.value
            }
        })
    };
    
    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoadedType(event.target.value as unknown as EngagementChartType);
        setChartDataPoint({
            ...chartDataPoint, type: event.target.value as unknown as EngagementChartType
        })
    };

    const getQuestionChip = (selected : EngagementQuestionHeaderOptionNoResponse[]) => {
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: EngagementQuestionHeaderOptionNoResponse) => (
                    <Chip key={value.questionHeaderId} label={value.questionHeader} />
                ))}
            </Box>
        )
    }

    const confirmData = async () => {
        setOptionsLoading(true);
        const apiClient = new FathomClient(await getAccessTokenSilently());

        var filter = {
            questionHeaderIdFilter: chartDataPoint.filter.questionHeaderIdFilter.map((y: any) => y.questionHeaderId),
            responseFilter: chartDataPoint.filter.responseFilter
        }

        chartDataPoint.filter = filter;
        console.log(selectedChart);
        await apiClient.put(`{clientId}/programs/${programId}/engagement/${engagementId}/chart/${selectedChart.dataPointId}`, chartDataPoint);
        setOptionsLoading(false);
        fetchData(engagementId);
        clearModal();

    }

    const handleCopyChart = createChartCopyHandler({
        containerSelector: '.modal-content',
        onSuccess: () => {
            enqueueSnackbar("Chart copied to your clipboard!", {
                variant: "success",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                }
            });
        },
        onError: (error) => {
        }
    });

    return (
        <Modal open={open} onClose={clearModal}>
            <Box className="modal-content">

                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} ><Typography variant="h6" component="h2">Edit Insight</Typography></Box>
                        <Box>
                            <IconButton onClick={clearModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <Grid container alignItems="center" spacing={2}> 
                    <Grid item xs={3}> 
                        <TextField
                        fullWidth
                        label="Insight Title"
                        value={chartDataPoint.title}
                        onChange={(e) => setChartDataPoint({ ...chartDataPoint, title: e.target.value })}
                        margin="normal"
                        />
                    </Grid>
                    {!dataLoading &&
                    <Grid item > 
                        <IconButton
                        aria-label="open"
                        sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
                        onClick={(ev) => handleCopyChart(ev)}
                        >
                        <ContentCopy fontSize="small" />
                        </IconButton>
                    </Grid>
                    }
                </Grid>
                {chartDataPoint?.filter?.questionHeaderIdFilter.length == 0 &&
                    <Typography sx={{ mt: 2, mb: 3 }}>Select questions from below to generate a chart</Typography>}
                <Box className="modal-body">

                    {
                        chartQueryData?.categories.length > 0 && chartDataPoint?.filter?.questionHeaderIdFilter.length > 0 &&
                        <>
                            {
                                chartDataPoint.type == EngagementChartType.Bar &&
                                <EngagementBarChart chart={chartQueryData} />
                            }
                            {
                                chartDataPoint.type == EngagementChartType.Pie &&
                                <PreviewBarChart chart={chartQueryData} />
                            }
                            {
                                chartDataPoint.type == EngagementChartType.Series &&
                                <EngagementSeriesChart chart={chartQueryData} />
                            }
                            <hr />

                        </>
                    }

                    {!optionsLoading &&
                        <>

                            <Grid container>
                                <Grid item xs={4}>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">Chart type</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"                                            
                                            name="radio-buttons-group"
                                            onChange={handleTypeChange}
                                            value={loadedType}
                                        >
                                            <FormControlLabel value={EngagementChartType.Bar} control={<Radio icon={<BarChartIcon />} sx={{
                                                '&.Mui-checked': {
                                                    color: '#6327E6',
                                                },
                                            }} checkedIcon={<BarChartIcon />} />} label="Bar" />
                                            <FormControlLabel value={EngagementChartType.Pie} control={<Radio icon={<PieChartIcon />} sx={{
                                                '&.Mui-checked': {
                                                    color: '#6327E6',
                                                },
                                            }} checkedIcon={<PieChartIcon />} />} label="Pie" />
                                            <FormControlLabel value={EngagementChartType.Series} control={<Radio icon={<SsidChartIcon />} sx={{
                                                '&.Mui-checked': {
                                                    color: '#6327E6',
                                                },
                                            }} checkedIcon={<SsidChartIcon />} />} label="Series" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl>
                                        <InputLabel id="question-filter">Questions</InputLabel>
                                        <Select
                                            labelId="question-filter"
                                            className="engagmentQuestionFilter"
                                            id="question-filter-chip"
                                            multiple
                                            value={questionFilter}
                                            onChange={handleHeaderChange}
                                            input={<Input id="select-multiple-chip" />}
                                            sx={{ maxWidth: "400px" }}
                                            renderValue={(selected) => getQuestionChip(selected)}
                                        >
                                            {chartOptions.headerResponses.map((header) => (
                                                <MenuItem 
                                                    value={header as any} 
                                                    key={header.questionHeaderId}
                                                >
                                                    <Tooltip title={header.questionHeader.length > 45 ? header.questionHeader : ''}>
                                                        <span>{header.questionHeader}</span>
                                                    </Tooltip>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
        
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl>
                                        <InputLabel id="response-filter">Responses</InputLabel>
                                        <Select
                                            labelId="response-filter"
                                            className="engagmentQuestionFilter"
                                            id="response-filter-chip"
                                            multiple
                                            value={chartDataPoint?.filter?.responseFilter}
                                            onChange={handleResponseChange}
                                            input={<Input id="select-multiple-chip" />}
                                            sx={{ maxWidth: "400px" }}

                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value: any) => (
                                                        <Chip key={value} label={value} className="enagement-filter-chip" />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {
                                                chartOptions.headerResponses
                                                    .filter((y) => { return chartDataPoint?.filter?.questionHeaderIdFilter.map((z: any) => (z.questionHeaderId || z)).indexOf(y.questionHeaderId) > -1 })
                                                    .flatMap((a) => a.responses)
                                                    .filter((x, i, a) => a.indexOf(x) == i)
                                                    .map((response) => (
                                                        <MenuItem key={response} value={response}  >
                                                            <Tooltip title={response.length > 45 ? response : ''} >
                                                                <span>{response}</span>
                                                            </Tooltip>
                                                        </MenuItem>

                                                    ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </>
                    }

                </Box>
                <Box className="modal-footer" sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
                    <Grid item xs={3}>
                        <Tooltip title={canSave() ? '' : "Please set a title and select at least one question"}>
                            <span><Button variant="outlined" disabled={!canSave()} onClick={() => confirmData()}>Confirm </Button></span>
                        </Tooltip>
                    </Grid>

                </Box>
            </Box>
        </Modal>
    );
}